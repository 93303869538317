// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Question from './components/Question';
import Result from './components/Result';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword'; // Import the component
import Register from './components/Register';
import VerifyEmail from './components/VerifyEmail'; // Import the VerifyEmail component

function App() {
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} /> {/* Ensure this route exists */}
      <Route path="/verify-email" element={<VerifyEmail />} /> {/* Add this route */}
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} /> {/* Add this route */}
      {isAuthenticated && <Route path="/home" element={<Home />} />}
      {isAuthenticated && <Route path="/question" element={<Question />} />}
      {isAuthenticated && <Route path="/result" element={<Result />} />}
      {!isAuthenticated && <Route path="*" element={<Login />} />}
    </Routes>
  );
}

export default App;
