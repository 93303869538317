// src/components/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './VerifyEmail.css';

function VerifyEmail() {
  const [message, setMessage] = useState('Verifying your email...');
  const navigate = useNavigate();
  const location = useLocation();

  // Get the token from the URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get('http://localhost:8002/auth/verify-email', {
          params: { token },
        });
        setMessage(response.data.detail || 'Email verified successfully!');
        // Optionally, redirect to login page after a delay
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        console.error('Email verification failed:', error);
        setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setMessage('Invalid or missing token.');
    }
  }, [token, navigate]);

  return (
    <div className="verify-email-container">
      <h2>Email Verification</h2>
      <p>{message}</p>
    </div>
  );
}

export default VerifyEmail;
