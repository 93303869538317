// src/components/Question.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNextQuestion, submitAnswer } from '../services/api';
import './Question.css';

function Question() {
  const [question, setQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const data = await getNextQuestion();
        setQuestion(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching question:', err);
        setError(true);
        setLoading(false);
      }
    };
    fetchQuestion();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      alert('Please select an option.');
      return;
    }
    try {
      const submission = {
        question_id: question.id,
        selected_option: selectedOption,
      };
      const result = await submitAnswer(submission);
      navigate('/result', { state: { result, question } });
    } catch (err) {
      console.error('Error submitting answer:', err);
      alert('An error occurred while submitting your answer. Please try again.');
    }
  };

  if (loading) {
    return <div className="question-container">Loading question...</div>;
  }

  if (error || !question) {
    return (
      <div className="question-container">
        Failed to load question. Please try again later.
      </div>
    );
  }

  return (
    <div className="question-container">
      <h2>{question.question_text}</h2>
      <form>
        <div className="option">
          <label>
            <input
              type="radio"
              value="A"
              checked={selectedOption === 'A'}
              onChange={handleOptionChange}
            />
            {question.option_a}
          </label>
        </div>
        <div className="option">
          <label>
            <input
              type="radio"
              value="B"
              checked={selectedOption === 'B'}
              onChange={handleOptionChange}
            />
            {question.option_b}
          </label>
        </div>
        <div className="option">
          <label>
            <input
              type="radio"
              value="C"
              checked={selectedOption === 'C'}
              onChange={handleOptionChange}
            />
            {question.option_c}
          </label>
        </div>
      </form>
      <button onClick={handleSubmit} className="submit-button">
        Submit Answer
      </button>
    </div>
  );
}

export default Question;
