// src/components/Result.js

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Result.css'; // Optional: Create for styling

function Result() {
  const location = useLocation();
  const navigate = useNavigate();
  const { result, question } = location.state || {};

  if (!result || !question) {
    return <div className="result-container">No result to display.</div>;
  }

  const { is_correct, updated_mastery } = result;

  const handleNextQuestion = () => {
    navigate('/question');
  };

  return (
    <div className="result-container">
      <h2>{is_correct ? 'Correct!' : 'Incorrect'}</h2>
      <p>
        You answered <strong>{is_correct ? 'correctly' : 'incorrectly'}</strong>.
      </p>
      {question.explanation && (
        <div className="explanation">
          <h3>Explanation:</h3>
          <p>{question.explanation}</p>
        </div>
      )}
      <div className="mastery-info">
        <h3>Your Mastery Level:</h3>
        <p>Stage: {updated_mastery.stage}</p>
        <p>Current Score: {updated_mastery.current_score}</p>
        <p>
          Next Due Date:{' '}
          {new Date(updated_mastery.next_due_date).toLocaleString()}
        </p>
      </div>
      <button onClick={handleNextQuestion} className="next-button">
        Next Question
      </button>
    </div>
  );
}

export default Result;
