// src/components/Register.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';
import axios from 'axios';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const checkPasswordStrength = (password) => {
    // Simple regex for demonstration purposes
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (strongPasswordRegex.test(password)) {
      setPasswordStrength('Strong');
    } else {
      setPasswordStrength('Weak');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (passwordStrength !== 'Strong') {
      alert('Please enter a stronger password.');
      return;
    }
    try {
      await axios.post('http://localhost:8002/auth/register', {
        email,
        password,
      });
      setMessage('Registration successful! Please check your email to verify your account.');
      // Optionally, redirect to login page after a delay
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error('Registration failed:', error);
      setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
    }
  };

  return (
    <div className="register-container">
      <h2>Create an Account</h2>
      <form onSubmit={handleRegister}>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password (min 8 chars, uppercase, lowercase, number)"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            checkPasswordStrength(e.target.value);
          }}
          required
        />
        <p>Password Strength: {passwordStrength}</p>
        <button type="submit">Sign Up</button>
      </form>
      {message && <p className="message">{message}</p>}
      <p>
        Already have an account?{' '}
        <button onClick={() => navigate('/login')} className="sign-in">
          Sign In
        </button>
      </p>
    </div>
  );
}

export default Register;
