// src/services/api.js

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Helper function to get auth headers
const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

/**
 * Fetch the next question for the user.
 * @returns {Promise<Object>} - The question object.
 */
export const getNextQuestion = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/questions/next`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

/**
 * Submit an answer to a question.
 * @param {Object} submission - The answer submission.
 * @param {number} submission.question_id - The ID of the question.
 * @param {string} submission.selected_option - The selected option (A, B, or C).
 * @returns {Promise<Object>} - The submission result.
 */
export const submitAnswer = async (submission) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/questions/submit`, submission, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

/**
 * Login function
 * @param {string} email
 * @param {string} password
 * @returns {Promise<Object>} - The token object.
 */
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

/**
 * Forgot Password function
 * @param {string} email
 * @returns {Promise<Object>} - The response message.
 */
export const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/forgot-password`, { email });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
