// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const tokenData = await login(email, password);
      localStorage.setItem('token', tokenData.access_token);
      navigate('/question');
    } catch (error) {
      console.error('Login failed:', error);
      alert('Invalid email or password');
    }
  };
  
  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };
  
  const handleSignUp = () => {
    navigate('/register');
  };
  
  return (
    <div className="login-container">
      <div className="login-left">
        <h1>AtomWissen</h1>
        <h2>Welcome Back!</h2>
        <p>Please enter your login details</p>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Sign In</button>
        </form>
        <button onClick={handleForgotPassword} className="forgot-password">
          Forgot Password?
        </button>
        <p>
          Don't have an account?{' '}
          <button onClick={handleSignUp} className="sign-up">
            Sign Up
          </button>
        </p>
      </div>
      <div className="login-right">
        <div className="image-placeholder">
          {/* Placeholder for future image or value proposition */}
          <p>Your CFA exam practice made easy.</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
